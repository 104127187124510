/* This example requires Tailwind CSS v2.0+ */
import { SpeakerphoneIcon, MailIcon, PhoneIcon } from '@heroicons/react/outline'

const Kontakt = (image) => {
  return (
    <>
      {/*
        Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
        your content from being obscured when the user scrolls to the bottom of the page.
      */}
      <div className="fixed inset-x-0 bottom-0">
        <div className="bg-color-8">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-color-8">
                  {/*
                  <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  */}
                  {/* LinkedIn */}
                  <a href="https://www.linkedin.com/in/kent-andreasen-42061648" target="_blank">
                    <img
                      className="h-6 cursor-pointer"
                      src={image.image}
                      alt="LinkedIn"
                    />
                  </a>
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">Ring eller skriv - helt uforpligtende <span className='ml-12'>Kent Andreasen</span></span>
                  <span className="hidden md:inline">Ring eller skriv - så mødes vi - helt uforpligtende <span className='ml-12'>Kent Andreasen</span></span>
                </p>
              </div>

              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto sm:mx-2">
                <a
                  href="tel:27271224"
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-color-8 bg-white hover:bg-blue-50"
                >
                  <PhoneIcon className="flex-shrink-0 h-5 w-5 text-color-8 mr-2" aria-hidden="true" /> 27 27 12 24
                </a>
              </div>

              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto sm:mx-2">
                <a
                  href="mailto:ka@effekt-raad.dk"
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-color-8 bg-white hover:bg-blue-50"
                >
                  <MailIcon className="flex-shrink-0 h-5 w-5 text-color-8 mr-2" aria-hidden="true" /> ka@effekt-raad.dk
                </a>
              </div>

              <div className="md:hidden sm:inline order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <div
                  className="-mr-1 flex p-2 rounded-md sm:-mr-2"
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default Kontakt;