const Bestyrelse = (image) => {
    return (
      <div className="relative bg-white sm:pb-8 pb-32">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/3">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full lg:inline md:hidden"
              src={image.image}
              alt="Kent Andreasen"
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-3">
          <div className="lg:col-start-1 lg:col-span-2">
            <div className="text-base max-w-prose mx-auto lg:max-w-7xl lg:ml-auto lg:mr-0">
              {/*
              <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Work with us</h2>
              */}
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-color-8 sm:text-4xl">
              Bestyrelse / Advisory Board
              </h3>
              <p className="mt-8 text-lg text-gray-500">
              Ønsker du et forum, hvor du kan få sparring, opbakning, blive udfordret, og i det hele taget få stillet en masse spørgsmål, og ikke mindst at du vil lytte, så kan det være en god idé at få en bestyrelse/Advisory Board.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              Undersøgelser viser, at virksomheder der har en bestyrelse gennemsnitligt, klarer sig bedre end virksomheder uden en bestyrelse.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              Jeg vil gerne være en aktiv del af virksomheden, og kan specielt bidrage med:
              </p>
              <p className="mt-2 text-lg text-gray-500">
              <span className="text-black font-bold">1:</span> Kapitalfremskaffelse</p>
              <p className="mt-2 text-lg text-gray-500">
              <span className="text-black font-bold">2:</span> Forretningsudvikling</p>
              <p className="mt-2 text-lg text-gray-500">
              <span className="text-black font-bold">3:</span> Strategisk sparring</p>
              <p className="mt-2 text-lg text-gray-500">
              <span className="text-black font-bold">4:</span> Salgsmodning</p>
              <p className="mt-8 text-lg text-gray-500">
              Jeg arbejder allerede aktivt i flere bestyrelser/Advisory Boards i en række virksomheder, som er forskellige i størrelse og brancher.
              </p>
              <p className="mt-8 text-lg text-color-8 font-semibold">
              Derfor er jeg også klar til at hjælpe dig og din virksomhed.
              </p>
              
  
              
            </div>
          </div>
        </div>
      </div>
    );
  }
   
  export default Bestyrelse;