const Forside = (image) => {
    return (
      <div className="relative bg-white mb-24" id="top">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src={image.image}
              alt="Kent Andreasen"
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-1 lg:pr-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:mr-auto lg:ml-0">
              {/*
              <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Work with us</h2>
              */}
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-color-8 sm:text-4xl">
                Velkommen til EFFEKT - rådgivning og finans
              </h3>
              <p className="mt-8 text-lg text-gray-500">
              <span className="text-color-8 font-semibold">EFFEKT</span> er en uvildig rådgivningsvirksomhed, hvor vi kan hjælpe i mange af de situationer, som en virksomhed kommer til at stå overfor. 
              Det kan være opstart, salg, generationsskifte eller kriser - store som små.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              Der vil også være tidspunkter, hvor virksomheden kan have brug for ny eller anderledes finansieringssammensætning eller et bedre og mere meningsfyldt samarbejde med virksomhedens omverden - det være sig bank, realkredit, revisor, advokat eller lignende.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              Enhver virksomhed uden mål ved ikke, hvornår den er en succes, og det smitter af
              på virksomhedens medarbejdere og deraf indtjening. En strategi - en ambitiøs plan - er meget værdifuld og specielt når den brydes ned og omsættes til handlinger i dagligdagen.
              Mange virksomheder kan have gavn af en bestyrelse, som udfordrer, hjælper og stiller de spørgsmål, som er med til at udvikle virksomheden.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              I alle disse situationer hjælper vi til - og tilfører den <span className="text-color-8 font-semibold">EFFEKT</span> der gør, at virksomhedens mål og drømme realiseres.
              <br /><p className="text-color-8 font-semibold">Ring eller skriv - så mødes vi - helt uforpligtende.</p>
              </p>
  
              
            </div>
          </div>
        </div>
        <span id="om"></span>
      </div>
    );
  }
   
  export default Forside;