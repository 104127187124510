import { MailIcon, PhoneIcon } from '@heroicons/react/outline'





// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getFirestore, collection, getDocs,
  addDoc, serverTimestamp
} from "firebase/firestore";
import { add } from 'lodash';
import { useState } from 'react';

// import { getFunctions } from 'firebase/functions';

// const app = initializeApp({
//   projectId: firebaseConfig.projectId,
//   apiKey: firebaseConfig.apiKey,
//   authDomain: firebaseConfig.authDomain,
// });
// const functions = getFunctions(app);


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDd1NUx1w9RtxPSaiAA1yS8OCrk0nYiGtw",
  authDomain: "effekt-raad.firebaseapp.com",
  projectId: "effekt-raad",
  storageBucket: "effekt-raad.appspot.com",
  messagingSenderId: "529331845856",
  appId: "1:529331845856:web:9e69315e2e2c806c7bee85"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// console.log(data)
const db = getFirestore();
const colRef = collection(db, 'message');




// Adding data
/*
const addMessageData = document.getElementsByClassName(add)
addMessageData.addEventListener('submit', (e) => {
  e.preventDefault()

  addDoc(colRef, {
    name: addMessageData.name.value,
    email: addMessageData.email.value,
    mobil: addMessageData.mobilnummer.value,
    text: addMessageData.text.value,
    timestamp: 'Day #1'
  })
  .then(() => {
    addMessageData.reset()
  })

})
*/





const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [mobil, setMobil] = useState('')
  const [text, setText] = useState('')

  function handleSubmit(e) {
    e.preventDefault();    
    console.log('You clicked submit.');

    addDoc(colRef, {
      name: name,
      email: email,
      mobil: mobil,
      text: text,
      timestamp: serverTimestamp(),
      date: Date()
    })

    setName('');
    setEmail('');
    setMobil('');
    setText('');

  }

  return (
    <div className="relative bg-white" id='kontakt'>
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-color-8 sm:text-3xl">Kontakt</h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Jeg kan kontaktes direkte over mobil, mail eller ved hjælp af formularen
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Adresse</dt>
                <dd>
                  <p>Myntevej 41,</p>
                  <p>8722 Hedensted</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Telefon nummer</dt>
                <dd className="flex">
                  <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">27 27 12 24</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">ka@effekt-raad.dk</span>
                </dd>
              </div>
            </dl>
            {/*
            <p className="mt-6 text-base text-gray-500">
              Looking for careers?{' '}
              <a href="#" className="font-medium text-gray-700 underline">
                View all job openings
              </a>
              .
            </p>
            */}
          </div>
        </div>



        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form onSubmit={handleSubmit} method="POST" className="grid grid-cols-1 gap-y-6 add" id="addData">
              <div>
                <label htmlFor="full-name" className="sr-only">
                  Full name
                </label>
                <input
                  type="text"
                  name="name"
                  id="full-name"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-color-8 focus:border-color-8 border-gray-300 rounded-md"
                  placeholder="Fulde navn"

                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-color-8 focus:border-color-8 border-gray-300 rounded-md"
                  placeholder="Email"

                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone
                </label>
                <input
                  type="text"
                  name="mobilnummer"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-color-8 focus:border-color-8 border-gray-300 rounded-md"
                  placeholder="Mobil"

                  value={mobil}
                  onChange={(e) => setMobil(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="text"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-color-8 focus:border-color-8 border border-gray-300 rounded-md"
                  placeholder="Besked"
                  

                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-color-8 hover:bg-color-9 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-color-8"
                >
                  Send Besked
                </button>
              </div>
            </form>
          </div>
        </div>



      </div>
    </div>
  );
}
 
export default Contact;