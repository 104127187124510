import './App.css';
// import Navbar from './component/NavBar.js';
// import First from './component/First.js';
// import Footer from './component/Footer.js';
import Main from './component/Main';
import Msg from './component/Msg'
// import logo from './img/logo.svg';
import img from './img/kent.jpg';



// Billeder af Kent
import kent3 from './img/demo-image.jpg';



// React Rounter Dom
import { BrowserRouter, Routes, Route } from 'react-router-dom';


// Billeder til Kents Hjemmeside
// Logo
import imgLogo from './img/logo2.svg';
// LinkedIn
import imgLinkedIn from './img/LinkedIn.svg';
// Forside
import imgForside from './img/Forside-mirror.jpg';
// Om EFFEKT
import imgOmEFFEKT from './img/OmEFFEKT.jpg';
// Bestyrelse / Advisory Board
import imgBestyrelse from './img/Bestyrelse.jpg';



function App() {
  return (
    <BrowserRouter>
      <div className="font-ubuntu">
        { /* Scroll to top */ }
        <div id="top"></div>
        <Routes>
          <Route  exact path='/' element={<Main image={img} case1={kent3} logo={imgLogo} social={imgLinkedIn} forside={imgForside} omEFFEKT={imgOmEFFEKT} bestyrelse={imgBestyrelse}/>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
