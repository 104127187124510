import { GlobeAltIcon, LightningBoltIcon } from '@heroicons/react/outline'







const Bund = () => {
  return (
    <div class="bg-white mb-24 pt-12">

      
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-color-8 sm:text-4xl">
                Ydelser
              </h3>

        <div class="mt-10">
          <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div class="relative">
              <dt>
                
                <p class="text-lg leading-6 text-color-8 font-semibold">Strategi</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
              At have en strategi eller en retning, er for mig at se ret afgørende for en virksomhed. Den skal selvfølgelig være operationel, så den bliver nedbrudt helt ned i dagligdags handlinger, og således man har en plan eller et mål at sigte efter.
              </dd>
            </div>

            <div class="relative">
              <dt>
                
                <p class="text-lg leading-6 text-color-8 font-semibold">Krise</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
              Er din virksomhed kriseramt, og har du brug for hjælp/sparring til at forsøge at bringe virksomheden tilbage på rette spor? Jeg kan hjælpe med at skabe overblik over situationen, samt sparring til, hvordan du kan forsøge at få lavet en turnaround i din virksomhed, så den igen bliver levedygtig.
              </dd>
            </div>

            <div class="relative">
              <dt>
                
                <p class="text-lg leading-6 text-color-8 font-semibold">Salgsmodning/generationsskifte</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
              Det at sælge eller generationsskifte en virksomhed, er oftest ikke bare noget man lige gør. Det kan kræve op til 5-10 års forberedelse, og min påstand er, at den forberedelse betaler sig. En virksomhed skal fremstå salgsklar, og selvom det er en større opgave, så er det i overført betydning ligesom at sælge en bil, den tager sig bedre ud, når den er vasket, støvsuget og poleret. Der kan være en meget stor værdi i at salgsmodne en virksomhed, så du som sælger får væsentlig mere ud af dit salg.
              </dd>
            </div>

            <div class="relative">
              <dt>
                
                <p class="text-lg leading-6 text-color-8 font-semibold">Finansiering</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
              Det kan være svært at skaffe den nødvendige finansiering i et pengeinstitut i dag. Pengeinstitutter er underlagt mange nye regler og lovgivning, og blandt andet derfor er det vigtigt, at virksomheden er godt forberedt til mødet med banken. Efter mange år i den finansielle sektor, så ved jeg hvad der skal til, og jeg kan også hjælpe med at få den rette finansiering – altså at finansieringsstrukturen bliver rigtig. 
              <br />
              <br />
              Det kan også være du har brug for hjælp til at finde et andet pengeinstitut, som bedre kan bakke op om, dine eventuelle vækstplaner eller bedre forstår din forretningsmodel. Nogle gange er der bare et bedre match i et andet pengeinstitut.
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <span id='bestyrelse'></span>
    </div>
  );
}
 
export default Bund;