// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getFirestore, collection, getDocs,
  addDoc 
} from "firebase/firestore";
import { add } from 'lodash';
import { useState } from 'react';

// import { getFunctions } from 'firebase/functions';

// const app = initializeApp({
//   projectId: firebaseConfig.projectId,
//   apiKey: firebaseConfig.apiKey,
//   authDomain: firebaseConfig.authDomain,
// });
// const functions = getFunctions(app);


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDd1NUx1w9RtxPSaiAA1yS8OCrk0nYiGtw",
  authDomain: "effekt-raad.firebaseapp.com",
  projectId: "effekt-raad",
  storageBucket: "effekt-raad.appspot.com",
  messagingSenderId: "529331845856",
  appId: "1:529331845856:web:9e69315e2e2c806c7bee85"
};



let msg = {};
  let name = 'Hej';
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  
  

  

const Msg = () => {

  // console.log(data)
  const db = getFirestore();
  const colRef = collection(db, 'message');
  getDocs(colRef)
    .then((snapshot) => {
      let message = []
      snapshot.docs.forEach((doc) => {
        message.push({ ...doc.data(), id: doc.id })

        document.write(
          doc.data().name,'<br>', 
          doc.data().email,'<br>',
          doc.data().mobil,'<br>',
          doc.data().text,'<br>',
          'ID: ',doc.id,'<br>',
          '<br>')

      })
      // console.log(message)
      // console.log(message[0])
      
      // document.write('Name: ',message[0].name)
    })
    .catch(err => {
      console.log(err.message)
    })
  

  return (
    <div>Navn: {name}</div>
  );
}
 
export default Msg;