import HeroSections from './HeroSections.js';
import Footer from './Footer.js';
import FAQ from './FAQ.js';
import Contact from './Contact.js';
import Content from './Content.js';
import Cases from './Cases.js';
import Ydelser from './Ydelser.js';

import Navbar from './NavBar.js';
import First from './First.js';
import Footer2 from './Footer2.js';
import logo from '../img/logo.svg';

// Navbar
import Navbar2 from './Navbar2.js';

// Forside
import Forside from './Forside.js';

// Om EFFEKT
import OmEFFEKT from './OmEFFEKT.js';

// Bestyrelse / Advisory Board
import Bestyrelse from './Bestyrelse.js';

// Bund
import Bund from './Bund.js';

// Kontakt
import Kontakt from './Kontakt.js';



const Main = (main) => {
  return (
    <div>
      <Navbar2 image={main.logo}/>
      <Forside image={main.forside} />
      <OmEFFEKT image={main.omEFFEKT} />
      <Bund />
      <Bestyrelse image={main.bestyrelse}/>
      <Kontakt image={main.social}/>




      {/*
      <HeroSections />
      <Content image={main.image}/>
      <Ydelser />
      */}



      {/*
      <Navbar />
      <First image={main.image} kent3={main.kent3}/>
      <img src={logo} alt="LinkedIn" className="w-80 cursor-pointer hidden"/>
      */}

      {/* 
      <h1 id="bottom" className="text-center font-ubuntu text-primary-1 bg-primary-3 py-2 font-bold">Marc Nygaard</h1>
      */}
      {/*
      <Footer2 />
      */}
      {/*


      <Cases case1={main.case1}/>
      <FAQ />
      <Contact />



      */}
      {/*
      <Footer />
      */}
    </div>
  );
}
 
export default Main;