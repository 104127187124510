const OmEFFEKT = (image) => {
    return (
      <div className="relative bg-white mb-24">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/3">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full lg:inline hidden"
              src={image.image}
              alt="Kent Andreasen"
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-3">
          <div className="lg:col-start-2 lg:col-span-2">
            <div className="text-base max-w-prose mx-auto lg:max-w-7xl lg:ml-auto lg:mr-0">
              {/*
              <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Work with us</h2>
              */}
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-color-8 sm:text-4xl">
                Om EFFEKT
              </h3>
              <p className="mt-8 text-lg text-gray-500">
              Jeg startede som selvstændig i sommeren 2021, efter en lang karriere på 30 år i den finansielle sektor, hvor jeg i den største del af tiden har beskæftiget mig med erhvervskunder og ledelse. Derigennem har jeg deltaget i en lang række finansieringsløsninger, lige fra vækst og iværksætteri til køb/salg af virksomheder, og ikke mindst deltaget i en række vanskelige finansieringer af kriseramte virksomheder.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              Jeg har derfor en rygsæk, der er pakket med en solid og bred erfaring i finansielle løsninger, og jeg har set, hvor stor en forskel det gør, at en virksomhed har en strategiplan, som er handlingsorienteret og kan bruges i dagligdagen. Jeg har hjulpet virksomheder – store som små – og i mange forskellige brancher, med at håndtere de mange forskellige udfordringer, der opstår i virksomhedens livscyklus.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              Jeg har opbygget et stort netværk, både i den finansielle sektor, men også et ret omfattende netværk udenfor, og dette netværk sætter jeg gerne i spil, for at finde en løsning på en problemstilling. Netop det faktum, at finde en løsning, er én af de ting, der altid har været i fokus gennem hele min karriere, da jeg er af den klare overbevisning, at ALLE er bedre stillet ved at finde en løsning. Derfor går jeg ”all-in” på at løse opgaven, ligesom jeg fra starten siger nej, hvis jeg ikke er den rigtige til at løse opgaven.<br /><br /><span>Kent Andreasen</span> 
              </p>
              
  
              
            </div>
          </div>
        </div>
        <span id="ydelser"></span>
      </div>
    );
  }
   
  export default OmEFFEKT;